import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/BD4GDGX9sr4">
    <SEO title="A Better Rest - Hebrews" />
  </Layout>
)

export default SermonPost
